/* Pagination Styles */
li {
  list-style: none;
}

.pagination-container {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.pagination-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 1px solid black; */      
  font-size: 20px;
  cursor: pointer;
}

.active {
  background: red;
  color: white;
}
